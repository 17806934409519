/* Next */
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

/* Redux */
import DrawerRedux from './Drawer.Redux'
import NavigationRedux from './Navigation.Redux'

/* Material */
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import useStyles from './styles'

/* Material-Icons */
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

/* Types */
import { tMenu, item } from 'src/shared/frontendTypes/navigation'

interface Props {
  paths: tMenu
  activeMenuId: number | null
  activeBrandId: number | null
}

const NavigationMenuPages: React.FC<Props> = ({ paths, activeMenuId, activeBrandId }) => {
  const classes = useStyles()
  const router = useRouter()
  const url = router.asPath

  const { toggle: toggleDrawer } = DrawerRedux()

  const { 
    displayMenuId, setDisplayMenuId,
    displayFilter, setDisplayFilter,
    resetFilter,
  } = NavigationRedux()

  const menuPages: JSX.Element[] = []

  /* ############################################################################ */
  const renderMenu = (
    paths: tMenu,
    mid: number,
    parent: number,
    parentBrand: item | null,
  ) => {
    const links: JSX.Element[] = []

    const renderLink = ({ id, menueId, title, path, brand, children }: item) => {
      let href: string | null = null

      if (brand) href = `/b/${path}/${brand}`

      if (menueId) {
        href = ''
        if (parentBrand) href = `/b/${parentBrand.path}/${parentBrand.brand}`
        href += `/m/${path}/${menueId}`
      }
      // Ursprünglich wurde hier geprüft, ob href gleich der aktuellen URL ist – das hatten wir entfernt.
      // Damit der Link aber beim erneuten Klick auf denselben Menüpunkt neu getriggert wird, prüfen wir:
      // Wenn router.asPath bereits gleich href (oder href + '#' ist), forciere einen Refresh.
      
      // Für "Marken" immer den Link "/brands" verwenden.
      if (title === 'Marken') href = '/brands'

      // Damit immer ein gültiger href vorhanden ist:
      if (!href) {
        href = '#' // Fallback, falls kein Link definiert wurde.
      }

      const listItem = (
        <ListItem
          key={id}
          component="li" button divider
          selected={ activeMenuId === menueId || activeBrandId === brand }
          onClick={async (e: React.KeyboardEvent | React.MouseEvent) => {
            // Bei gedrückten Modifier-Tasten (z. B. Ctrl) wird keine Zustandsänderung vorgenommen.
            if (e.ctrlKey || e.metaKey || e.shiftKey || e.altKey) {
              return
            }
            // Wenn der Link bereits aktiv ist (gleicher Pfad), dann forciere einen Refresh.
            if (router.asPath === href || router.asPath === href + '#') {
              e.preventDefault();
              // Wir ersetzen die aktuelle Route – damit werden alle Effekte neu getriggert.
              await router.replace(href);
              return;
            }
            // Falls Unterordner vorhanden sind, öffnen wir den entsprechenden Zweig.
            if (children) {
              e.preventDefault();
              setDisplayMenuId(id);
              return;
            }
            // Andernfalls: normaler Klick – Zustand ändern und Navigation durchführen.
            if (path && href) {
              if (!brand) {
                // Hier wird der Filter aktiviert – wenn er bereits aktiv ist, kann es sein, dass er nicht neu getriggert wird.
                // Wir setzen ihn hier neu.
                setDisplayFilter(true);
              }
              resetFilter();
              toggleDrawer(e);
            }
          }}
        >
          {children
            ? <ListItemIcon><ArrowDropDownIcon /></ListItemIcon>
            : <ListItemIcon><ArrowRightIcon /></ListItemIcon>
          }
          <ListItemText primary={title} />
        </ListItem>
      );

      return (
        <Link key={id} legacyBehavior href={href}>
          <a>{listItem}</a>
        </Link>
      );
    };

    for (const pathObj of paths) {
      links.push(renderLink(pathObj));
      if (pathObj.children) {
        renderMenu(
          pathObj.children,
          pathObj.id, // Verwende die ID des aktuellen Menüpunktes als neuen mid.
          mid, // Der übergeordnete Wert bleibt gleich.
          pathObj.brand ? pathObj : parentBrand,
        );
      }
    }

    const backButton = (
      <ListItem
        component="li" button divider
        onClick={(e: React.MouseEvent)=>{
          e.preventDefault();
          setDisplayMenuId(parent);
        }}
      >
        <ListItemIcon><ArrowBackIcon /></ListItemIcon>
        <ListItemText primary='Zurück' />
      </ListItem>
    );

    if (!displayFilter && displayMenuId === mid)
      menuPages.push(
        <List key={`menu-${mid}`} className={classes.list}>
          {mid !== 0 && backButton}
          {links}
        </List>
      );
  };

  renderMenu(paths, 0, 0, null);

  return <>{menuPages}</>;
}

export default NavigationMenuPages;