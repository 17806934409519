/* React */
import { useEffect } from 'react'

/* Redux */
import { useDispatch } from 'src/redux/hooks'

/* Types */
import { hasOwnProperty } from 'src/functions/typeGuards'
import { 
  isItem as isWarenkorbItem,
  isItems as isWarenkorbItems,
} from 'src/shared/frontendTypes/warenkorb'
import {
  isStorageKasse
} from 'src/shared/frontendTypes/kasse'
import { 
  isItem as isWunschlisteItem,
  isItems as isWunschlisteItems,
} from 'src/shared/frontendTypes/wunschliste'

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

const CheckLocalStorage = () => {
  const dispatch = useDispatch()

  const loadUser = () => {
    const storedUser = localStorage.getItem('user')
    if (!storedUser) { dispatch({ type: 'logout' }); return }
    const user = JSON.parse(storedUser)
    dispatch({ type: 'login', payload: user })
  }

  const loadWarenkorb = () => {
    // Versuch, den Eintrag "warenkorb" aus dem LocalStorage zu lesen
    const storedWarenkorb = localStorage.getItem('warenkorb')
    if (!storedWarenkorb) return
    
    const parsed = JSON.parse(storedWarenkorb)
  
    // 1. Prüfe, ob der Eintrag überhaupt ein Objekt ist
    if (!parsed || typeof parsed !== 'object') return
  
    // 2. Prüfe, ob parsed ein "items"-Feld enthält und ob dieses valide ist
    if (!('items' in parsed)) return
  
    // 3. Fallback für coupon und couponObjekt
    const couponCode = typeof parsed.coupon === 'string' ? parsed.coupon : null
    const couponObj = parsed.couponObjekt ? (parsed.couponObjekt as tcoupon) : null
  
    // 4. Dispatch in EINEM Rutsch
    dispatch({
      type: 'restoreWarenkorbItems',
      payload: {
        items: parsed.items,
        coupon: couponCode,            // string | null
        couponObjekt: couponObj,       // tcoupon | null
      },
    })
  }

  const loadKasse = () => {
    const storedKasse = localStorage.getItem('kasse')
    if (!storedKasse) return
    const kasse:unknown = JSON.parse(storedKasse)
    if (!isStorageKasse(kasse)) return
    dispatch({ type: 'restoreKasse', payload: kasse })
  }

  const loadWunschliste = () => {
    const storedWunschliste = localStorage.getItem('wunschliste')
    if (!storedWunschliste) return
    const wunschliste:unknown = JSON.parse(storedWunschliste)
    if (
      !wunschliste
      || typeof wunschliste !== 'object'
      || !hasOwnProperty(wunschliste, 'items')
      || !isWunschlisteItems(wunschliste.items)
      || !Object.values(wunschliste.items).every(item=>isWunschlisteItem(item))
      ) return
    dispatch({ type: 'restoreWunschlisteItems', payload: wunschliste.items })
  }

  const loadAll = () => {
    loadUser()
    loadWarenkorb()
    loadKasse()
    loadWunschliste()
  }

  const onFocus = () => loadAll()

  useEffect(() => {
    loadAll()
    window.addEventListener('focus', onFocus, { passive: true })

    return () => window.removeEventListener('focus', onFocus)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default CheckLocalStorage

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
