import { tItem } from 'src/shared/frontendTypes/warenkorb'
import { tcouponFrontend } from 'src/shared/frontendTypes/coupon'
import clone from 'src/functions/cloner'

type items = { [column: string]: tItem }

const items: items = {}

const initialState = {
  timestamp: <string|false>false,
  items,
  coupon: null as string | null,
  couponObjekt: null as tcouponFrontend | null,
}

export type tWarenkorb = typeof initialState

/* +++++++++++++++++++++++++++++++++++++++++++++++++ */

export type action =
  | {
      type: 'restoreWarenkorbItems'
      payload: {
        items: items,
        coupon: string | null,
        couponObjekt: tcouponFrontend | null
      }
    }
  | {
      type: 'addWarenkorbItem'
      payload: tItem
    }
  | {
      type: 'removeWarenkorbItem'
      payload: string
    }
  | {
      type: 'qtyWarenkorbItem'
      payload: { 
        key: string
        qty: number
      }
    }
  | {
      type: 'clearWarenkorb'
      payload?: undefined
    }
  | {
      type: 'applyCoupon'
      payload: {
        coupon: string | null;
        couponObjekt: tcouponFrontend | null;
      }
    }

/* +++++++++++++++++++++++++++++++++++++++++++++++++ */

const save = (state: tWarenkorb) => {
  setTimeout(() => {
    localStorage.setItem('warenkorb', JSON.stringify(state, (key, value) => {
      // Wenn wir im couponObjekt sind, filtere das Feld "coupon" heraus
      if(key === 'couponObjekt' && value && typeof value === 'object'){
        // Entferne den Schlüssel "coupon" aus dem Objekt
        const { coupon, ...rest } = value;
        return rest;
      }
      return value;
    }));
  }, 0);
  return { ...state, timestamp: new Date().toISOString() };
}

const reducer = (state = initialState, {type,payload}: action): tWarenkorb => {
  switch (type) {

    /* -------------------------------------------------------------- */

    case 'restoreWarenkorbItems': {
      return save({ ...state,
        items: payload.items,
        timestamp: new Date().toISOString(),
        coupon: payload.coupon || null,
        couponObjekt: payload.couponObjekt || null,
      })
    }

    /* -------------------------------------------------------------- */

    case 'addWarenkorbItem': {
      const items = clone(state.items)
      const item = payload
      const { key, qty } = item

      if (!items[key]) items[key] = item
      else             items[key].qty += qty

      return save({ ...state,
        items,
      })
    }

    /* -------------------------------------------------------------- */

    case 'removeWarenkorbItem': {
      const items = clone(state.items)
      delete items[payload]
      return save({ ...state,
        items,
      })
    }

    /* -------------------------------------------------------------- */

    case 'qtyWarenkorbItem': {
      const items = clone(state.items)
      const { key, qty } = payload

      if (qty > 0)
        items[key].qty = qty
      else
        delete items[key]

      return save({ ...state,
        items,
      })
    }
    
    /* -------------------------------------------------------------- */

    case 'applyCoupon': {
      return save({
        ...state,
        coupon: payload.coupon,               // Der reine Code als String
        couponObjekt: payload.couponObjekt,   // Das vollständige Objekt
      })
    }
    /* -------------------------------------------------------------- */

    /*case 'clearWarenkorb': {
      return save({ ...state,
        items: {},
      })
    }*/

    /* -------------------------------------------------------------- */

    default: return state

    /* -------------------------------------------------------------- */
    
  }
}

export default reducer