import { z } from 'zod'
import { couponTypeSchema } from '../couponTypes'

// Definiere die Struktur eines einzelnen Coupon-Items
export interface CouponItem {
  orderId?: number
  orderDate?: Date
  discount?: number
}

// Zod-Schema für ein einzelnes Coupon-Item
const CouponItem: z.ZodType<CouponItem> = z.lazy(() =>
  z.object({
    orderId: z.number().optional(),
    orderDate: z.date().optional(),
    discount: z.number().optional(),
  })
)

// Zod-Schema für ein Array von Coupon-Items
export const couponItemsSchema = z.array(CouponItem)
export type CouponItems = z.infer<typeof couponItemsSchema>

// Zod-Schema für den Coupon
export const couponSchema = z.object({
  id: z.number(),
  title: z.string(),
  type: couponTypeSchema,
  code: z.string().optional(),
  slogan: z.string().optional(),
  balance: z.string().optional(),
  brand: z.number().optional(),
  shops: z.array(z.number()).optional(),
  isMultiUse: z.boolean().optional(),
  usageLimit: z.number().nullable().optional(),
  active: z.boolean().optional(),
  stackable: z.boolean().optional(),
  coupon: couponItemsSchema.optional().default([]),
  created: z.string().transform(val => new Date(val)), // wenn du immer einen ISO-String bekommst
  changed: z.string().transform(val => new Date(val)),
  expirationDate: z.string().optional().transform(val => val ? new Date(val) : undefined),
  deleted: z.literal(true).optional(),
})
export type tcoupon = z.infer<typeof couponSchema>
export const couponFrontendSchema = couponSchema;
export type tcouponFrontend = z.infer<typeof couponFrontendSchema>;